
/*  FOOTER 1 - GLOBALS
--------------------------------------------------------------------------------------------*/



//
//     PRIMARY
//

@if $footer-primary-enabled == true {
    .ac_footer_primary {
        @if $footer-primary-background != transparent or $footer-primary-background != none {
            background: $footer-primary-background;
        }
        * {
            color: $footer-primary-color;
        }
    }

    .ac_footer_primary_container {
        max-width: $footer-content-width;
        margin: 0 auto;
        padding:  $footer-primary-padding;
        display: flex;
        flex-wrap: wrap;
        @include breakpoint(medium down) {
            padding-bottom: 0;
        }
    }

    .ac_footer_primary_column {
        padding: 0 $default-padding / 2;
        width: 100%;
        
        @if $footer-primary-column-1-enabled == false {
             &:nth-child(1) {
                 display: none;
             }
        }@if $footer-primary-column-2-enabled == false {
             &:nth-child(2) {
                 display: none;
             }
        }
        @if $footer-primary-column-3-enabled == false {
             &:nth-child(3) {
                 display: none;
             }
        }
        @if $footer-primary-column-4-enabled == false {
             &:nth-child(4) {
                 display: none;
             }
        }

        @include breakpoint(medium down) {
            margin-bottom: $default-padding * 2;
        }
        
        @include breakpoint(medium) {
            &:nth-child(1) {
                flex: 0 1 percentage($footer-primary-column-width-1);
                max-width: percentage($footer-primary-column-width-1);
            }
            &:nth-child(2) {
                flex: 0 1 percentage($footer-primary-column-width-2);
                max-width: percentage($footer-primary-column-width-2);
            }
            &:nth-child(3) {
                flex: 0 1 percentage($footer-primary-column-width-3);
                max-width: percentage($footer-primary-column-width-3);
            }
            &:nth-child(4) {
                flex: 0 1 percentage($footer-primary-column-width-4);
                max-width: percentage($footer-primary-column-width-4);
            }
        }

        @if $footer-primary-collapsed-align-center == true {
            @include breakpoint(medium-portrait down) {
                text-align: center;
            }
        }
        
        @include breakpoint(medium-portrait down) {

            @if $footer-primary-collapsed-hide-column-1 == true {
                &:nth-child(1) {
                    display: none;
                }
            }
            @if $footer-primary-collapsed-hide-column-2 == true {
                &:nth-child(2) {
                    display: none;
                }
            }
            @if $footer-primary-collapsed-hide-column-3 == true {
                &:nth-child(3) {
                    display: none;
                }
            }
            @if $footer-primary-collapsed-hide-column-4 == true {
                &:nth-child(4) {
                    display: none;
                }
            }
        }
    }
    a {
        color: $footer-primary-link-color !important;
        text-decoration: underline;
        &:hover {
            color: $footer-primary-link-hover-color !important;
        }
    }
} @else {
    .ac_footer_primary { 
        display: none;
    }
}


//
//     SECONDARY
//

@if $footer-secondary-enabled == true {
    .ac_footer_secondary {
        @if $footer-secondary-background != transparent or $footer-secondary-background != none {
            background: $footer-secondary-background;
        }
        * {
            color: $footer-secondary-color;
        }
    }
    .ac_footer_secondary_container {
        max-width: $footer-content-width;
        margin: 0 auto;
        padding:  $footer-secondary-padding;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .ac_footer_secondary_column {
        padding: 0 $default-padding / 2;
        width: 100%;
        
        @if $footer-secondary-column-1-enabled == false {
             &:nth-child(1) {
                 display: none;
             }
        }@if $footer-secondary-column-2-enabled == false {
             &:nth-child(2) {
                 display: none;
             }
        }


        @include breakpoint(medium) {
            &:nth-child(1) {
                flex: 0 1 percentage($footer-secondary-column-width-1);
                max-width: percentage($footer-secondary-column-width-1);
            }
            &:nth-child(2) {
                flex: 0 1 percentage($footer-secondary-column-width-2);
                max-width: percentage($footer-secondary-column-width-2);
            }
        }

        @if $footer-secondary-collapsed-align-center == true {
            @include breakpoint(medium-portrait down) {
                text-align: center;
                margin-bottom: $default-padding;
                .ac_footer_secondary_link, 
                .ac_footer_secondary_text {
                    display: block;
                }
            }
        }
        @include breakpoint(medium) {
            display: flex;
            align-items: center;
            .ac_footer_secondary_link, 
            .ac_footer_secondary_text {
                margin-right: $default-padding / 2;
            }
            &:nth-child(1) {
                justify-content: $footer-secondary-column-1-justify;
            }
            &:nth-child(2) {
                justify-content: $footer-secondary-column-2-justify;
                .ac_footer_secondary_link, 
                .ac_footer_secondary_text {
                    margin-left: $default-padding / 2;
                }
            }
        }

    }
    .ac_footer_secondary_text {
        white-space: nowrap;
    }
    .ac_footer_secondary_link {
        color: $footer-secondary-link-color;
        white-space: nowrap;
        &:hover {
            color: $footer-secondary-link-hover-color;
        }
    }
} @else {
    .ac_footer_secondary {
        display: none;
    }
}


//
//     SOCIALS
//

@if $footer-secondary-enabled == true {
    @if $footer-secondary-collapsed-align-center == true {
        .ac_socials {
            justify-content: center;
        }
    }
    @include breakpoint(medium-portrait down) {
        .ac_socials {
            margin: $default-padding 0;
        }
    }

    .ac_socials_link_icon {
        background: $footer-socials-icon-background;
        svg {
            fill:  $footer-socials-icon-color;
        }
        &:hover {
            @include breakpoint(large) {
                background: $footer-socials-icon-hover-background;
                svg {
                    fill:  $footer-socials-icon-hover-color;
                }
            }
        }
    }
}