/* STUCTURE
--------------------------------------------------------------------------------------------*/
body {

}

figure {
    margin: 0;
}



a {
    // transition: all $default-transition-speed;
}



// fix for non animatables
[data-anime-type="none"] [data-anime-elem]{ 
    opacity: 1; 
}

// add stroke to all icons
.svg-container{
    svg{
        stroke: $brand-secondary;
        fill: $brand-primary;
        stroke-width: 1.2;
        transform: scale(1.2)
    }
}

// .svg_line {
//     svg {
//         fill: none !important;
//         stroke: green;
//         stroke-width: 5px;
//         stroke-linecap: round;
//         stroke-miterlimit: 100;
//         stroke-dasharray: 1000;
//         stroke-dashoffset: 1000;
//         animation: dash 3s ease-out forwards;
//         animation-delay: 1s;
//     }
// }

// @keyframes dash {
//   to {
//     stroke-dashoffset: 0;
//   }
// }
