/* GENERICS - BUTTON GROUP
--------------------------------------------------------------------------------------------*/
$button-svg-size: 2.8em;
$button-border: none;
$button-padding: 1em;

//
//     BUTTON BEHAVIOUR
//

button, .button {
    &:active {
        transform: translateY(1px);
    }
    &:focus {
         outline: none; 
     }
}

.button { // only style class elements, not the HTML button elements
    
    //
    //      DEFAULTS
    //

    // text-transform: uppercase;
    font-weight: 400;
    padding: $button-padding;
    text-align: left;
    border-radius: 4px;
    border: $button-border;
    border-color: $default-text-color-light;
    position: relative;
    transition: background .3s ease;
    will-change: background;
    display: inline-flex;
    align-items: center;
    font-size: 100%;

    //default bg
    background: $brand-secondary;
    // background: linear-gradient(-90deg, $brand-primary-darker, $brand-primary);
    color: $default-text-color-light;
    

    .svg-container {
        display: none;
        width: $button-svg-size;
        height: $button-svg-size;
    }
    svg {
        width: $button-svg-size / 2;
        height: $button-svg-size / 2;
        fill: currentColor;
    }
    &.v_expanded {
        display: block;
        width: 100%;
    }

    //
    //     BRAND CLASSES
    //

    // generate brand button classes
    @mixin generate-btn-classes {
        @each $key, $value in $brand-color-list {
            &.v_brand_#{$key} {
                background: map-get($brand-color-list, $key);
                color: find-contrast(map-get($brand-color-list, $key)); // text is automatically checked for contrast. Can be overwtitten manually.

                svg {
                    fill: find-contrast(map-get($brand-color-list, $key)); // text is automatically checked for contrast. Can be overwtitten manually.
                }
            }   
        }
    }
    @include generate-btn-classes();
    
    //
    //     BRAND GHOST CLASSES
    //

    &.v_ghost {
        background: none;
    }

    // generate brand button ghost classes
    @mixin generate-btn-ghost-classes {
        @each $key, $value in $brand-color-list {
            &.v_ghost_brand_#{$key} {
                background: none;
                color: map-get($brand-color-list, $key);
                border-color: map-get($brand-color-list, $key);
            }   
        }
    }
    @include generate-btn-ghost-classes();

    //
    //     ICON
    //

    &.v_has-icon-left {
        flex-direction: row-reverse;
        padding:  $button-padding - ($button-padding * .3);
        padding: 0 0 0 1em;
        .svg-container {
            background: $brand-primary-darker;
            display: inline-block;
            margin-right: $button-svg-size / 4;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        
    }
    &.v_has-icon-right {
        // padding: $button-padding - ($button-padding * .3);
        padding: 0 0 0 1em;
        .svg-container {
            transform: translateY(0);
            background: $brand-primary-darker;
            display: inline-block;
            margin-left: $button-svg-size / 4;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    //
    //     TRANSPARENT CLASSES
    //

    &.v_transparent {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        background: none;
        border: none;
        color: $default-text-color;
        &:hover {
            color: $default-text-color;
            opacity: 1;
        }
        .svg-container {
            background: transparent;
        }
    }

    &.v_transparent_body {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        background: none;
        border: none;
        text-transform: none;
        color: $default-text-color;

        @include font-body-regular;
        .svg-container {
            background: transparent;
            width: $button-svg-size * .7;
            height: $button-svg-size * .7;
        }
        svg {
            width: $button-svg-size * .7;
            height: $button-svg-size * .7;
        }
        &:hover {
            color: $default-text-color;
            opacity: 1;
        }
    }

    &.v_smaller {
        padding: $button-padding *.7;
        font-size: 85%;
    }
    &.v_smallest {
        padding: $button-padding *.6;
        font-size: 80%;        
    }

    //
    //     HOVER STATE
    //
   
    &:hover {
        background: $brand-darkest-gray!important;
    }
}
