
/* HERO - 1
--------------------------------------------------------------------------------------------*/

[data-s-type="hero"] {

    // hero
    $hero-height: 60vh; // 100vh, 80vh, 400px, etc
    $hero-min-height: 500px; // optional and handy for smaller sizes, to prevent collapsing of content
    $hero-gradient-before: false;
    $hero-gradient-before-height: 100%;
    $hero-gradient-before-position: 'top';
    $hero-gradient-color: rgba($brand-black, .8);


    // slide
    $hero-slide-max-width: 1200px;
    $hero-slide-content-correction: 0; // -3em // allows for visual correction of outlining content, when using a divider
    $hero-slide-type: 'text'; // text or text-image
    $hero-slide-align: 'left'; // center or left. text-image always implies left aligned text
    $hero-slide-content-text-width: 1*.5; // can be any percentage calculation, eg 1*.8 or 1/4. The image (if applicable) will take the space that is left
    $hero-slide-image-on-small: 'absolute'; // none or absolute; how to show the optional image in a slide on small devices
    $hero-slide-image-on-small-opacity: .4;

    // arrows
    $hero-slide-arrows-enabled: true;
    $hero-slide-arrows-enabled-from: 700px; // 700px
    $hero-slide-arrows-increase-size-from: 1150px;
    $hero-slide-arrows-size-small: 1.5em;
    $hero-slide-arrows-size-large: 2em;
    $hero-slide-arrows-position: 'bottom-centered'; // left-and-right-centered, bottom-centered
    $hero-slide-arrows-bottom-position: 10em; // if bottom-centered
    $hero-slide-arrows-color: $default-icon-color-light;
    $hero-slide-arrows-hover-color: rgba($default-icon-color-light, .5);

    // dots
    $hero-slide-dots-border: solid 2px rgba($default-icon-color-light, .5); 
    $hero-slide-dots-border-active: solid 2px rgba($default-icon-color-light, .5); 
    $hero-slide-dots-background: transparent; 
    $hero-slide-dots-background-active: $default-background-light; 

    // link to content
    $hero-link-to-content-enabled: true;
    $hero-link-to-content-bottom: 2em;
    $hero-link-to-content-enable-for-medium-portrait-down: false;
    $hero-link-to-content-color: $default-icon-color-light;
    $hero-link-to-content-hover-color: rgba($default-icon-color-light, .5);


    @import '_hero_globals';


    /*--------------------------------------------------------------------------------------------*/
    
    background: red !important;
    h1, h2, h3 {
        line-height: 1;
    }
    p {
        line-height: 1.5;
    }
    h1, h2, h3, p{
        text-shadow: 0 0 4px rgba($brand-black, .75);
    }

    * {
        &:focus {
            outline: none;
        }
    }

    .ac_hero_container {
        .ac_cta_column{
            a.button:nth-child(2){
                @include breakpoint(1038px down){
                    margin-left: 0;
                    margin-top: .4rem;
                }
            }
        }
    }

    .ac_hero_slide_content_container {
        @include breakpoint(small only){
            transform: translateY($hero-slide-content-correction / 2);
        }
    }

    .ac_hero_slide_content_text {
        padding: 10px 0;
        color: $brand-white;
    }

    .ac_hero_slide_content_text_title {
        margin-bottom: 1em;
        * {
            font-size: 140%;
            
            color: $brand-white;
            display: inline;
            line-height: 1.3;
            box-decoration-break: clone;
            padding: 0;
            @include breakpoint(medium) {
                font-size: 180%;
            }
        }
    }

    .ac_cta_column{
        a.button{
            background: $brand-secondary;
            padding: 8px 12px;
            color: $brand-white;
            font-weight: 700;
            &:hover{
                .svg-container{
                    svg{
                        transform: scale(.8) translateX(3px);
                    }
                }
            }
            .svg-container{
                margin-left: .4rem;
                svg{
                    transition: transform .3s;
                    stroke: currentColor;
                    stroke-width: 2px;
                    transform: scale(.8);
                }
            }
            &:nth-child(2){
                margin-left: .6rem;
                background: $brand-primary;
                transition: background .3s;
                &:hover{
                    background: $brand-primary-darker;
                }
            }
        }
    }

    .slick-dots {
        @include breakpoint(small only){
            bottom: 2em;
        }
    }

    

} // end of scope