
/* SERVICES - 2
--------------------------------------------------------------------------------------------*/

[data-s-type="services"] {

    // items slider
    $items-slider-fouc-height: 200px; // what needs to be the flash-of-unwanted-content height before slider has laoded.

    // items
    $items-amount: 0; // 0 counts for undefined, if assigned the code will be more optimised
    $items-content-over-image: true;
    $items-have-padding: true;
    $items-have-border-dividers: false;
    $items-have-border-below: false;
    $items-hide-border-below-on-last-row: true;
    $items-border-radius: 0; 
    $items-first-item-full-width: false;
    $items-first-item-full-width-height-multiplier: 1.5;
    $items-on-small-two-items-a-row: false;
    $item-background: none;
    $item-background-hover: none;

    // columns
    $items-columns-enabled: false;
    $items-columns-breakpoint: 800px; 
    $items-columns-height: 480px; 
    $items-columns-hide-last-one-below-breakpoint: false;
    $items-columns-icon-image-combination: true;

    // item
    $item-margin-bottom: 1.4em; // 1.4em
    $item-border: $default-border; // defines the appearance, not the display
    $item-shadow-enabled: false;

    // image
    $item-image-container-enabled: false;
    $item-image-enabled: false;
    $item-image-height: 240px;
    $item-image-initial-fade: 1;
    $item-image-hover-fade: .8;
    $item-image-gradient-before: true; // when item-content-over-image
    $item-image-gradient-color: $default-background-dark;
    $item-image-gradient-before-position: 'bottom'; // top or bottom
    $item-image-background-color: $default-background-dark;
    $item-image-background-color-hover: $default-background-dark;
    $item-image-border-radius: $default-border-radius; 

    // content
    $item-content-padding: 0; // $default-padding; 
    $item-content-background: $brand-white;
    $item-content-background-hover: $brand-lightest-gray;
    $item-content-align: 'cc'; // lt, lc, lb, ct, cc, cb, rt, rc, rb ||  when item-content-over-image
    $item-content-copy-padding: $default-padding 0 ; 
    $item-content-copy-align: 'left'; // left or center

    $item-content-icon-enabled: true;
    $item-content-icon-centered: true;
    $item-content-icon-color: rgba($default-icon-color, 1);
    $item-content-icon-hover-color: rgba($default-icon-color, 1);
    $item-content-icon-size: 100px;
    $item-content-icon-padding: 0px;
    $item-content-icon-margin: 0 0 30px 0;
    $item-content-icon-background: $brand-secondary;
    $item-content-icon-background-hover: $brand-secondary;
    $item-content-icon-round: false;
    
    $item-content-title-enabled: true; 
    $item-content-title-color: $brand-white;
    $item-content-title-hover-color: $brand-secondary;

    $item-content-copy-above-enabled: false;    
    $item-content-copy-above-color: rgba($default-text-color-light, .6);
    $item-content-copy-above-hover-color: rgba($default-text-color-light, .4);

    $item-content-copy-below-enabled: false;    
    $item-content-copy-below-color: rgba($default-text-color-light, .6);
    $item-content-copy-below-hover-color: rgba($default-text-color-light, .4);

    // icon
    $item-show-icon: false;
    $item-icon-color: rgba($default-icon-color-light, .4);
    $item-icon-hover-color: rgba($default-icon-color-light, 1);
    $item-icon-size: 1.6em;
    $item-icon-position: 'rb'; // lt, lb, rt, rb
    $item-icon-inset-left-right: $default-padding; 
    $item-icon-inset-top-bottom: $default-padding; 
    

    // list style on small | image left and content right | all background will be reset | content icon is set absolute. Not possible in conjunction with a slider
    $item-list-style-on-small: false; 
    $item-list-style-image-width: 80px;
    $item-list-style-content-icon-size: 60px; 
    $item-list-style-content-icon-padding: 14px; 
    $item-list-style-content-icon-background: $default-background-light;
    $item-list-style-content-icon-color: $default-icon-color-dark;
    $item-list-style-content-title-color: rgba($default-text-color, 1);
    $item-list-style-content-copy-above-color: rgba($default-text-color, 1);
    $item-list-style-content-copy-below-color: rgba($default-text-color, 1);

    @import '../ac_s__items-globals/__item_globals';
   

    /*--------------------------------------------------------------------------------------------*/

    h1, h2, h3{
        margin: 0 0 $default-padding * 2 0;
        text-transform: uppercase;
    }

    .ac_container{
        margin: 0 auto;
    }

    .ac_item_image-container {
        border: solid 3px $brand-white;
    }
    .ac_item_image {
        border-radius: 0;
    }

    .ac_item_content_title {
        @include font-headings-bold;
        font-size: 1.1rem;
        line-height: 1;
        white-space: nowrap;
    }

    &[data-s-amount="3"]{
        .ac_item{
            @include breakpoint(medium-portrait only){
                padding-left: $default-padding;
                padding-right: $default-padding;
                margin-bottom: $default-padding * 2;
                &:nth-child(2n){
                    padding-right: $default-padding / 2;
                }
                &:nth-child(2n - 1){
                    padding-left: $default-padding / 2;
                }
        
                &:nth-last-child(1),
                &:nth-last-child(2){
                    margin-bottom: 0;
                }
            }
            @include breakpoint(medium){
                padding-left: $default-padding;
                padding-right: $default-padding;
                margin-bottom: $default-padding * 2;
                &:nth-child(3n){
                    padding-right: $default-padding / 2;
                }
                &:nth-child(3n - 2){
                    padding-left: $default-padding / 2;
                }
        
                &:nth-last-child(1),
                &:nth-last-child(2),
                &:nth-last-child(3){
                    margin-bottom: 0;
                }
            }


        }
    }

    .ac_item {
        transition: all .3s ease;
        @include breakpoint(large) {
            &:hover {
                transform: translateY(-6px);
               
            }
        }

        &:hover{
            transform: none;
            .ac_item_content_title{
                color: $brand-secondary!important;
            }
            .ac_item_content_icon{
                transform: scale(1.2);
                svg{
                    fill: $brand-primary;
                }
            }
        }

        .ac_item_content{
            border-radius: 4px;
        }

        .ac_item_content_icon{
            will-change: transform;
            position: relative;
            border-radius: 100%;
            background: none!important;
            transition: transform .4s ease, background .4s ease;
            svg{
                position: absolute;
                stroke: $brand-secondary;
                stroke-width: 1.25;
                color: $brand-primary!important;
                *{
                   color: $brand-primary!important; 
                }
                width: calc(100% - #{$item-content-icon-padding * 2});
                height: calc(100% - #{$item-content-icon-padding * 2});
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                fill: $brand-primary;
            }
        }
    }

} // end scope