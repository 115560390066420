/* SECTION CONTROL
--------------------------------------------------------------------------------------------*/

[data-s-type="brands"]{
    border-top: 1px solid $brand-lightest-gray;
}

[data-s-type="bruggeman-intro"]{
    @include breakpoint(large down){
        img {
            padding-bottom: $default-padding;
        }
    }
}