
/* AC_S_TOPTASKS - GLOBALS
--------------------------------------------------------------------------------------------*/


@mixin ac-toptask-border-divider-bottom() {
    top: inherit;
    right: inherit;
    left: $default-padding;
    bottom: 0;
    height: 1px;
    width: calc(100% - #{$default-padding * 2});
}

//
//      TOPTASKS SECTION
//


[data-s-type="toptasks"] {

    position: relative;
}

//
//      CONTAINER
//


.ac_toptasks_container{
    max-width: $ac-toptasks-items-max-width;
    margin: 2em auto;
}
.ac_toptasks_container_inner {
    display: flex;
    flex-wrap: wrap;
    padding: $ac-toptasks-items-inner-wrapper-padding;

    @if $ac-toptasks-items-inner-wrapper-enabled == true {
        background: $ac-toptasks-items-inner-wrapper-background-color;
        border-radius: $ac-toptasks-item-border-radius;
    }

}


//
//      ITEM
//

.ac_toptasks_item {
    display: flex;
    padding: $ac-toptasks-item-padding;
    position: relative;
    width: 100%;
    transform: translateY(0);
    transition: all .3s;
    
    @if $ac-toptasks-items-have-border-dividers == true {
        &:after {
            content: '';
            position: absolute;
            top: 20%;
            right: 0;
            height: 60%;
            width: 1px;
            background: $default-border-color;
            @include breakpoint(small only) { 
                @include ac-toptask-border-divider-bottom;
            }
        }
        @include last(1) {
            &:after {
                display: none;
            }
        }
    }

    &:hover {

        @include breakpoint(large) {    
            .ac_toptasks_item_container {
                background: $ac-toptasks-item-background-hover-color;
            }
            .ac_toptasks_item_image {
                opacity: $ac-toptasks-item-has-image-hover-fade;
            }
            .ac_toptasks_item_icon {
                background: $ac-toptasks-item-icon-hover-bg;
                svg {
                     @if $svg-icon-set-type == 'fill' {
                        fill: $ac-toptasks-item-icon-hover-fill;
                     } @elseif $svg-icon-set-type == 'stroke' {
                        stroke: $ac-toptasks-item-icon-hover-fill;
                        fill: transparent;
                    }
                }
            }
            .ac_toptasks_item_content_title {
                color: $ac-toptasks-item-title-color-hover
            }
            @if $ac-toptasks-item-description-enabled == true {
                .ac_toptasks_item_content_description {
                    color: $ac-toptasks-item-description-color-hover;
                }
            }
            @if $ac-toptasks-item-btn-container-enabled == true {
                .ac_toptasks_item_content_btn-container {
                    color: $ac-toptasks-item-btn-container-color-hover;
                }
            }
        }
    }
}

.ac_toptasks_item_container {
    background: $ac-toptasks-item-background-color;
    width: 100%;
    border-radius: $ac-toptasks-item-border-radius;
    padding: $ac-toptasks-item-container-padding;
    position: relative;
    transition: all $default-transition-speed;
}


//
//      BORDER RESPONSIVENESS
//

@if $ac-toptasks-items-have-border-dividers == true {

    
}


//
//      IMAGE
//

.ac_toptasks_item_image-container {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    border-radius: 100%;
    @if $ac-toptasks-item-has-image-container == false {
        display: none; 
    }
}
.ac_toptasks_item_image {
    @if $ac-toptasks-item-has-image == false {
        display: none; 
    } @else {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: $ac-toptasks-item-has-image-initial-fade;
        border-top-left-radius: $ac-toptasks-item-border-radius;
        border-top-right-radius: $ac-toptasks-item-border-radius;
        transition: all $default-transition-speed;
        box-shadow: $default-box-shadow;
    }
}


//
//      CONTENT
//


.ac_toptasks_item_content {
    position: relative;
    z-index: 1;
    flex: 1; // ie11 text overflow fix
}             
.ac_toptasks_item_content_title {
    @include font-headings-bold();
    font-size: 120%;
    margin-bottom: .4em;
    max-width: 100%;
    line-height: 1;
    color: $ac-toptasks-item-title-color;
    transition: all $default-transition-speed;
}
.ac_toptasks_item_content_description {
    transition: all $default-transition-speed;
    @if $ac-toptasks-item-description-enabled == false {
        display: none; 
    } @else {
        margin-bottom: $ac-toptasks-item-description-margin-bottom;
        max-width: 100%;
        color: $ac-toptasks-item-description-color;
    }
}
.ac_toptasks_item_content_btn-container {
    @if $ac-toptasks-item-btn-container-enabled == false {
        display: none;
    } @else {
        max-width: 100%;
        color: $ac-toptasks-item-btn-container-color;
    }
}


[data-s-type="toptasks"][data-s-amount="3"] [data-s-amount-item]{
    @include breakpoint(large down){
        width: 50%;
        max-width: 50%;
    }
    @include breakpoint(medium-portrait down){
        width: 100%;
        max-width: 100%;
    }
}


//
//      ICON
//

.ac_toptasks_item_icon-container {
    @if $ac-toptasks-item-icon-enbled == true {
        position: absolute;
        z-index: 1;
        margin-bottom: $ac-toptasks-item-icon-margin-bottom
    } @else {
        display: none;
    }
}
.ac_toptasks_item_icon {
    @if $ac-toptasks-item-icon-enbled == true {
        
        background: $ac-toptasks-item-icon-bg;
        padding: 0;
        transition: all $default-transition-speed;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        @include breakpoint(medium up){
            width: $ac-toptasks-item-icon-width;
            height: $ac-toptasks-item-icon-width;
        }

        svg {
            width: $ac-toptasks-item-icon-width - $ac-toptasks-item-icon-padding;
            height: $ac-toptasks-item-icon-width - $ac-toptasks-item-icon-padding;
            transition: all $default-transition-speed;
            @if $svg-icon-set-type == 'fill' {
                fill: $ac-toptasks-item-icon-fill;
            } @elseif $svg-icon-set-type == 'stroke' {
                stroke: $ac-toptasks-item-icon-fill;
                fill: transparent;
            }
        }
    } @else {
        display: none;
    }
}



