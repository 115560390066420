
/* AC_S_TOPTASKS - 1
--------------------------------------------------------------------------------------------*/

// items
$ac-toptasks-items-max-width: $max-content-1200;
$ac-toptasks-items-padding: 0;
$ac-toptasks-items-inner-wrapper-enabled: false;
$ac-toptasks-items-inner-wrapper-padding: $default-padding / 4;
$ac-toptasks-items-inner-wrapper-background-color: $brand-secondary;
$ac-toptasks-items-have-border-dividers: false;

// item
$ac-toptasks-item-padding: $default-padding / 2;
$ac-toptasks-item-container-padding: $default-padding;
$ac-toptasks-item-has-shadow: true;
$ac-toptasks-item-background-color: $brand-lightest-gray;
$ac-toptasks-item-background-hover-color: $brand-light-gray;
$ac-toptasks-item-border-radius: $default-border-radius; 

// image
$ac-toptasks-item-has-image-container: false; 
$ac-toptasks-item-has-image-container-height: 120px;
$ac-toptasks-item-has-image-container-background-color: $brand-tertiary;
$ac-toptasks-item-has-image: false;
$ac-toptasks-item-has-image-initial-fade: .1;
$ac-toptasks-item-has-image-hover-fade: .5;

// icon
$ac-toptasks-item-icon-enbled: true;
$ac-toptasks-item-icon-width: 80px; // px of em
$ac-toptasks-item-icon-padding: 24px; // px or em - is deducted from icon width. Say width is 80px and padding is 15px, the actual icon is 50px;
$ac-toptasks-item-icon-margin-bottom: 20px; 
$ac-toptasks-item-icon-bg: $brand-black;
$ac-toptasks-item-icon-hover-bg: $brand-black;
$ac-toptasks-item-icon-fill: $brand-primary;
$ac-toptasks-item-icon-hover-fill: $brand-primary;

// title
$ac-toptasks-item-title-color: $brand-black;
$ac-toptasks-item-title-color-hover: $brand-black;

// description
$ac-toptasks-item-description-enabled: true;
$ac-toptasks-item-description-color: $brand-black;
$ac-toptasks-item-description-color-hover: $brand-black;
$ac-toptasks-item-description-margin-bottom: 1em;

// btn-container
$ac-toptasks-item-btn-container-enabled: true;
$ac-toptasks-item-btn-container-color: $default-text-color;
$ac-toptasks-item-btn-container-color-hover: $default-text-color;

@import '__toptasks_globals';

/*--------------------------------------------------------------------------------------------*/
.ac_toptasks_container_inner{
    padding-top: $ac-toptasks-item-icon-width / 2;
    @include breakpoint(small only){
        padding-top: $default-padding;
    }
}

.ac_toptasks_item {
    transition: transform .4s ease;
    @include breakpoint(medium-portrait){
        padding-top: $ac-toptasks-item-icon-width / 2;
        padding-bottom: $ac-toptasks-item-icon-width / 2;
        &:first-child{
            padding-top: 0;
        }
        &:last-child{
            padding-bottom: 0;
        }
    }
    @include breakpoint(medium){
        padding-right: $default-padding;
        padding-left: $default-padding;
        &:first-child{
            padding-left: $default-padding / 2;
        }
        &:nth-child(2){
            padding-top: 0;
            padding-right: $default-padding / 2;
        }
        &:last-child{
            padding-left: $default-padding / 2;
        }
    }
    @include breakpoint(xlarge){
        padding-top: 0;
        padding-bottom: 0;
        &:first-child{
            padding-left: $default-padding / 2;
        }
        &:last-child{
            padding-right: $default-padding / 2;
            padding-left: $default-padding;
        }
    }
    @include breakpoint(small only){
        overflow: hidden;
        padding-top: $default-padding * 3;
    }
    .ac_toptasks_item_icon-container {
        will-change: transform;
    }
    .ac_toptasks_item_content_btn-container{
        .button{
            font-weight: 700;
            background: $brand-secondary;
        }
    }
    &:hover{
        transform: translateY(-4px);
        .ac_toptasks_item_container {
            box-shadow: 0 4px 0 0 rgba($brand-black, .3);
        }
        .ac_toptasks_item_content_btn-container{
            .button{
                background: $brand-secondary;
                opacity: 1!important;
            }
        }
        .button{
            background: $brand-darkest-gray!important;
        }
    }
    &:active{
        transition: none;
        transform: translateY(0);
        .ac_toptasks_item_container {
            transition: none;
            box-shadow: 0 0 0 0 rgba($brand-black, .3);
        }
    }
}
.ac_toptasks_item_container {
    transition: background .4s ease, box-shadow .4s ease;
    border-radius: 6px;
    display: flex;
    align-items: center;
    @include breakpoint(medium-portrait) { 
        display: block;
    }
}
.ac_toptasks_item_icon-container {
    margin-top:  0;
    position: absolute;
    left: 50%;
    top: 0;
    transition: transform .4s ease;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    @include breakpoint(small only) {
        flex: 0 0 $ac-toptasks-item-icon-width * .66;
        padding-right: $default-padding;
    }
    @include breakpoint(medium-portrait) { 
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.ac_toptasks_item_icon {
    border-radius: 100%;
    background: $brand-black;
    svg{
        stroke: $brand-secondary;
        stroke-width: 1.5;
        *{
            color: $brand-primary!important;
        }
    }
    @include breakpoint(medium down){
        width: $ac-toptasks-item-icon-width / 1.2;
        height: $ac-toptasks-item-icon-width / 1.2;
        svg {
            width: $ac-toptasks-item-icon-width / 1.5;
            height: $ac-toptasks-item-icon-width / 1.5;  
        }
    }
}
.ac_toptasks_item_content {
    padding: $default-padding * 3 0 $default-padding;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

