/* GENERICS - ANCHORS
--------------------------------------------------------------------------------------------*/
a { 
    // //disable tel links on large
    // &[href^="tel"] {
    //     @include breakpoint(large) {
    //         pointer-events: none; // dis
    //     }
    // }
}


//default anchors in content
.ac_content {
    a:not(.button):only-child {
        color: $brand-secondary;
        text-decoration: underline;
        transition: color .4s ease;
        &:hover {
            color: $brand-gray !important;

        }
    }
}