/* GENERICS - DROPDOWN
--------------------------------------------------------------------------------------------*/

#menu-main-menu > li.menu-item-has-children > a::after {
    border: inset 3px;
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: $brand-white transparent transparent;
    right: 7px;
    margin-top: -3px;
}

#menu-secundair-menu > li.menu-item-has-children > a::after {
    border: inset 3px;
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: $brand-white transparent transparent;
    right: 7px;
    margin-top: -3px;
}

.is-dropdown-submenu {
    border: none;
    box-shadow: 2px 2px 4px rgba(black, .1), 2px 2px 10px rgba(black, .1);
}

