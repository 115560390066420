/* PAGE CONTROL
--------------------------------------------------------------------------------------------*/
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .main-container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    section:nth-last-child(3):not([data-s-type="hero-alt"]){
      flex-grow: 1;
    }
    section:nth-last-child(2):not([data-s-type="cta"]){
      flex-grow: 1;
    }
  }
}

.page-id-179,
.page-id-177{
  [data-s-type="hero-alt"],
  [data-s-type="hero-alt"].a_bg_gradient_brand_primary{
    background: $brand-secondary!important;
  }
}